// @ts-strict-ignore
import { useEffect } from 'react'

import { Formik } from 'formik'
import { useRouter } from 'next/router'

import { ApolloError } from '@apollo/client'

import { Heading3, Heading6 } from '~/theme/utils/typography'
import { formatErrorMessages } from '~/utils/formatErrorMessages'

import { getClubConfig } from '~/config/clubConfig'
import { useSetPasswordMutation } from '~/generated/graphql'
import { gt } from '~/locale'
import Button from '~/shared/atoms/Button'
import Input from '~/shared/atoms/Input'
import { useToast } from '~/shared/atoms/Toast'
import { useModal } from '~/shared/molecules/Modal'

import LoginModal from '../LoginModal'
import PasswordChangedModal from '../PasswordChangedModal'

import { StyledNewPasswordForm, StyledSetNewPasswordModal, StyledToastContainer } from './SetNewPasswordModalShards'
import { FormValues } from './SetNewPasswordModalTypes'
import { loginModalProps, modalProps } from './SetNewPasswordModalUtils'

const SetNewPasswordModalView = () => {
  const [setPassword] = useSetPasswordMutation()
  const { query, ...router } = useRouter()
  const modal = useModal()
  const toast = useToast()
  const { paths } = getClubConfig()
  const { resetPasswordUrl } = paths

  const handleErrors = (error: ApolloError) => {
    const errors = formatErrorMessages(error)
    errors?.map(error =>
      toast.add({
        message: error,
        type: 'error',
        containerSelector: '#SetPasswordToastContainer',
        size: 'small',
      })
    )
  }

  const handleSubmit = (data: FormValues) => {
    setPassword({
      variables: {
        password: data.password,
        resetPasswordToken: `${query?.resetPasswordToken}`,
      },
      onCompleted: () => {
        const handleSuccessClick = () => {
          router.replace(resetPasswordUrl.onExitUrl, undefined, { shallow: resetPasswordUrl.shallow })

          modal.close()
          modal.open(loginModalProps, <LoginModal handleClose={modal.close} />)
        }

        modal.open(modalProps, <PasswordChangedModal handleClick={handleSuccessClick} />)
      },
      onError: error => handleErrors(error),
    })
  }

  return (
    <StyledSetNewPasswordModal>
      <Heading3 fontWeight='semiBold'>{gt.tp('SetNewPasswordModal', 'Set up a new password')}</Heading3>
      <Heading6 fontWeight='regular'>
        {gt.tp(
          'SetNewPasswordModal',
          'A new password must include both uppercase and lowercase letters and be at least 8 characters long.'
        )}
      </Heading6>
      <Formik<FormValues>
        initialValues={{
          password: '',
          repeatPassword: '',
        }}
        validationSchema={undefined}
        onSubmit={handleSubmit}>
        {({ values, handleSubmit, isValid, handleChange, handleBlur, errors, touched }) => (
          <StyledNewPasswordForm>
            <StyledToastContainer id={'SetPasswordToastContainer'} />
            <Input
              height='normal'
              width='full'
              title={gt.tp('SetNewPasswordModal', 'Password*')}
              type={'password'}
              error={touched.password && !!errors.password}
              errorMessage={touched.password && gt.tp('Error', errors.password)}
              onChange={handleChange}
              value={values.password}
              name={'password'}
              onBlur={handleBlur}
              required
            />
            <Input
              height='normal'
              width='full'
              title={gt.tp('SetNewPasswordModal', 'Repeat password*')}
              type={'password'}
              error={touched.repeatPassword && !!errors.repeatPassword}
              errorMessage={touched.repeatPassword && gt.tp('Error', errors.repeatPassword)}
              onChange={handleChange}
              value={values.repeatPassword}
              name={'repeatPassword'}
              onBlur={handleBlur}
              required
            />
            <Button
              label={gt.tp('SetNewPasswordModal', 'Change password')}
              color='green'
              width='full'
              height='big'
              fontWeight='regular'
              onClick={handleSubmit}
              disabled={!isValid}
            />
          </StyledNewPasswordForm>
        )}
      </Formik>
    </StyledSetNewPasswordModal>
  )
}

export const SetNewPasswordWrapper = (Component: any): React.FC<any> => {
  const InnerComponent = props => {
    const { query, ...router } = useRouter()
    const modal = useModal()
    const resetPasswordToken = query?.resetPasswordToken
    const { paths } = getClubConfig()
    const { resetPasswordUrl } = paths

    useEffect(() => {
      if (resetPasswordToken) {
        modal.open(
          {
            ...modalProps,
            handleClose: () => {
              modal.close()
              router.replace(resetPasswordUrl.onExitUrl, undefined, { shallow: resetPasswordUrl.shallow })
            },
          },
          <SetNewPasswordModalView />
        )
      }
    }, [query?.resetPasswordToken])

    return <Component {...props} />
  }

  return InnerComponent
}

export default SetNewPasswordModalView
